// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-skill{
  width: 90%;
  margin-top: 3rem;
  box-shadow: 0px 15px 10px -5px rgba(128, 128, 128, 0.7);
  animation-duration: 2s;
}

.card-skill-header{
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-skill-title{
  margin: 1.5rem auto;
  font-weight: bold;
}

.card-skill-list{
  font-size: 1.2rem;
}

.card-skill-list li{
  margin-bottom: 0.8rem;
}

.card-skill img{
  width: 2rem;
  margin-right: 1rem;
}

@media (min-width: 770px  ) {
  .card-skill{
    width: 45%; 
  }
}

@media (min-width: 1224px  ) {
  .card-skill{
    width: 24%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/CardSkill/Style.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,uDAAuD;EACvD,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".card-skill{\n  width: 90%;\n  margin-top: 3rem;\n  box-shadow: 0px 15px 10px -5px rgba(128, 128, 128, 0.7);\n  animation-duration: 2s;\n}\n\n.card-skill-header{\n  text-align: center;\n  color: white;\n  font-weight: bold;\n  font-size: 1.5rem;\n  height: 90px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.card-skill-title{\n  margin: 1.5rem auto;\n  font-weight: bold;\n}\n\n.card-skill-list{\n  font-size: 1.2rem;\n}\n\n.card-skill-list li{\n  margin-bottom: 0.8rem;\n}\n\n.card-skill img{\n  width: 2rem;\n  margin-right: 1rem;\n}\n\n@media (min-width: 770px  ) {\n  .card-skill{\n    width: 45%; \n  }\n}\n\n@media (min-width: 1224px  ) {\n  .card-skill{\n    width: 24%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
