// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-img-container {
  max-height: 80%;
  overflow: hidden;
}


.modal-img {
  width: 100% !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/ModalImage/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;AAClB;;;AAGA;EACE,sBAAsB;AACxB","sourcesContent":[".modal-img-container {\n  max-height: 80%;\n  overflow: hidden;\n}\n\n\n.modal-img {\n  width: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
