// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page_404{ padding:40px 0; background:#fff; font-family: 'Arvo', serif;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
 background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
 }
 
 
 .four_zero_four_bg h1{
 font-size:80px;
 }
 
  .four_zero_four_bg h3{
       font-size:80px;
       }
       
       .link_404{      
  color: #fff!important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;}
  .contant_box_404{ margin-top:-50px;}`, "",{"version":3,"sources":["webpack://./src/pages/Error/style.css"],"names":[],"mappings":"AAAA,WAAW,cAAc,EAAE,eAAe,EAAE,0BAA0B;AACtE;;AAEA,gBAAgB,UAAU,CAAC;;AAE3B;;CAEC,+FAA+F;IAC5F,aAAa;IACb,2BAA2B;CAC9B;;;CAGA;CACA,cAAc;CACd;;EAEC;OACK,cAAc;OACd;;OAEA;EACL,qBAAqB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,qBAAqB,CAAC;EACxB,kBAAkB,gBAAgB,CAAC","sourcesContent":[".page_404{ padding:40px 0; background:#fff; font-family: 'Arvo', serif;\n}\n\n.page_404  img{ width:100%;}\n\n.four_zero_four_bg{\n \n background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);\n    height: 400px;\n    background-position: center;\n }\n \n \n .four_zero_four_bg h1{\n font-size:80px;\n }\n \n  .four_zero_four_bg h3{\n       font-size:80px;\n       }\n       \n       .link_404{      \n  color: #fff!important;\n    padding: 10px 20px;\n    background: #39ac31;\n    margin: 20px 0;\n    display: inline-block;}\n  .contant_box_404{ margin-top:-50px;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
