// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.navbar-brand{
  display: block;
  text-align: center;
  font-size: 1rem;
  display: block;
  text-align: center;
  font-size: 1rem;
  position: absolute;
  right: 0;
  top: 0;
  font-weight: bold;
}
@media (min-width: 991px) {
  .navbar-brand{
    display: none;
  }
 }

.nav-item{
  font-size: 1.6rem;
  margin: 1rem 1.5rem;
  padding: 0.5rem 2rem;
  text-decoration: none;
  color: black;
}

.nav-item:nth-child(1):hover{
 background: linear-gradient(45deg, #E6A8FD, #0CAAD9) ;
 border-radius: 20px 40px 5px ;
 
}

.nav-item:nth-child(2):hover{
  background: linear-gradient(45deg, #E6A8FD, #0CAAD9) ;
  border-radius: 40px 15px 25px  ;
 }

 .nav-item:nth-child(3):hover{
  background: linear-gradient(45deg, #E6A8FD, #0CAAD9) ;
  border-radius: 13px 40px 22px  ;
 }

 .nav-item:nth-child(4):hover{
  background: linear-gradient(45deg, #E6A8FD, #0CAAD9) ;
  border-radius: 40px 15px 25px  ;
 }




@media (min-width: 320px) {
 img{
  width: 4rem;
 }
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/style.css"],"names":[],"mappings":";AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,QAAQ;EACR,MAAM;EACN,iBAAiB;AACnB;AACA;EACE;IACE,aAAa;EACf;CACD;;AAED;EACE,iBAAiB;EACjB,mBAAmB;EACnB,oBAAoB;EACpB,qBAAqB;EACrB,YAAY;AACd;;AAEA;CACC,qDAAqD;CACrD,6BAA6B;;AAE9B;;AAEA;EACE,qDAAqD;EACrD,+BAA+B;CAChC;;CAEA;EACC,qDAAqD;EACrD,+BAA+B;CAChC;;CAEA;EACC,qDAAqD;EACrD,+BAA+B;CAChC;;;;;AAKD;CACC;EACC,WAAW;CACZ;AACD","sourcesContent":["\n.navbar-brand{\n  display: block;\n  text-align: center;\n  font-size: 1rem;\n  display: block;\n  text-align: center;\n  font-size: 1rem;\n  position: absolute;\n  right: 0;\n  top: 0;\n  font-weight: bold;\n}\n@media (min-width: 991px) {\n  .navbar-brand{\n    display: none;\n  }\n }\n\n.nav-item{\n  font-size: 1.6rem;\n  margin: 1rem 1.5rem;\n  padding: 0.5rem 2rem;\n  text-decoration: none;\n  color: black;\n}\n\n.nav-item:nth-child(1):hover{\n background: linear-gradient(45deg, #E6A8FD, #0CAAD9) ;\n border-radius: 20px 40px 5px ;\n \n}\n\n.nav-item:nth-child(2):hover{\n  background: linear-gradient(45deg, #E6A8FD, #0CAAD9) ;\n  border-radius: 40px 15px 25px  ;\n }\n\n .nav-item:nth-child(3):hover{\n  background: linear-gradient(45deg, #E6A8FD, #0CAAD9) ;\n  border-radius: 13px 40px 22px  ;\n }\n\n .nav-item:nth-child(4):hover{\n  background: linear-gradient(45deg, #E6A8FD, #0CAAD9) ;\n  border-radius: 40px 15px 25px  ;\n }\n\n\n\n\n@media (min-width: 320px) {\n img{\n  width: 4rem;\n }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
